export default {
  src: "/assets/img/icons-mobile-template.png",
  patches: [
    {
      name: "type_0_gray",
      offsetX: -783,
      offsetY: 292,
      width: 50,
      height: 48,
    },
    {
      name: "type_1_gray",
      offsetX: 0,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_2_gray",
      offsetX: -60,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_3_gray",
      offsetX: -120,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_4_gray",
      offsetX: -180,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_5_gray",
      offsetX: -240,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_6_gray",
      offsetX: -300,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_7_gray",
      offsetX: -360,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_8_gray",
      offsetX: -420,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_9_gray",
      offsetX: -480,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_10_gray",
      offsetX: -540,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_11_gray",
      offsetX: -600,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_12_gray",
      offsetX: -660,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_13_gray",
      offsetX: -720,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_14_gray",
      offsetX: -780,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_15_gray",
      offsetX: -840,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_16_gray",
      offsetX: -900,
      offsetY: 400,
      width: 50,
      height: 48,
    },
    {
      name: "type_17_gray",
      offsetX: -840,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_18_gray",
      offsetX: -781,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_19_gray",
      offsetX: -902,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_20_gray",
      offsetX: -840,
      offsetY: 292,
      width: 50,
      height: 48,
    },
    {
      name: "type_1_orange",
      offsetX: 0,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_2_orange",
      offsetX: -60,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_3_orange",
      offsetX: -120,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_4_orange",
      offsetX: -180,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_5_orange",
      offsetX: -240,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_6_orange",
      offsetX: -300,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_7_orange",
      offsetX: -360,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_8_orange",
      offsetX: -420,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_9_orange",
      offsetX: -480,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_10_orange",
      offsetX: -540,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_11_orange",
      offsetX: -600,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_12_orange",
      offsetX: -660,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "type_13_orange",
      offsetX: -720,
      offsetY: 350,
      width: 50,
      height: 48,
    },
    {
      name: "corner_add",
      offsetX: 0,
      offsetY: 300,
      width: 27,
      height: 27,
    },
    {
      name: "status_bar",
      offsetX: -30,
      offsetY: 300,
      width: 322,
      height: 24,
    },
    {
      name: "icon_arrow_up_gray",
      offsetX: 0,
      offsetY: 256,
      width: 24,
      height: 24,
    },
    {
      name: "icon_arrow_down_gray",
      offsetX: 0,
      offsetY: 227,
      width: 24,
      height: 24,
    },
    {
      name: "icon_arrow_up_orange",
      offsetX: -29,
      offsetY: 256,
      width: 24,
      height: 24,
    },
    {
      name: "icon_arrow_down_orange",
      offsetX: -29,
      offsetY: 228,
      width: 24,
      height: 24,
    },
    {
      name: "icon_close_gray",
      offsetX: 0,
      offsetY: 198,
      width: 24,
      height: 24,
    },
    {
      name: "icon_close_orange",
      offsetX: -29,
      offsetY: 198,
      width: 24,
      height: 24,
    },
    {
      name: "icon_scan_gray",
      offsetX: 0,
      offsetY: 169,
      width: 24,
      height: 24,
    },
    {
      name: "icon_scan_orange",
      offsetX: -29,
      offsetY: 169,
      width: 24,
      height: 24,
    },
  ],
};
