import Vue from "vue";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import {
  ClientType,
  PageType,
  getChildFloorConfig,
  getFloorConfig,
  publishChildFloorConfig,
  publishFloorConfig,
  getGoodsSpecialSubject,
  publishSGoodsSpecialSubject,
  detectGoodsStatus,
  saveCkeditor,
} from "../api";
import { FloorModule } from "../modules/module.config";
import { $floorModules } from "../modules";
import { generateKey } from "./key";
import { getApp } from "./app";
import { floorVersions } from "@/api/floor";

/**
 * 楼层单项数据块
 */
export interface FloorItemBlock {
  block_type: string;
  block_value: any;
  block_opt: any;
}

/**
 * 楼层单项实例
 */
export class FloorItem {
  key = generateKey();
  tpl_id: number;
  blockList: FloorItemBlock[];
  template: any;
  templateEl!: HTMLElement;
  editor: any;
  setBackStatus: any;

  onChange = new BehaviorSubject({ list: null });
  isEditing = new BehaviorSubject(false);

  isChange!: () => boolean;
  onReset!: Subject<any>;

  private _keyText = this.key.split("-")[0].toUpperCase();
  private subscriptions: Subscription[] = [];

  get keyText() {
    return `ID: ${this._keyText}`;
  }

  static create(module: FloorModule, isNew = false) {
    return new FloorItem(
      {
        tpl_id: module.type,
        blockList: [],
        template: module.template,
        editor: module.editor,
      },
      isNew
    );
  }

  constructor(
    data: { tpl_id: number; blockList: any[]; template?: any; editor?: any },
    public isNew = false
  ) {
    this.tpl_id = data.tpl_id;
    this.blockList = data.blockList;
    this.template = data.template;
    this.editor = data.editor;
    if (!this.template || !this.editor) {
      const module = $floorModules.getModule(this.tpl_id);

      if (module) {
        this.template = module.template;
        this.editor = module.editor;
      }
    }

    this.subscriptions = [
      $floor.itemSelected.subscribe((item) => {
        if (!item) return;
        this.isEditing.next(item.key === this.key);
      }),
    ];
  }

  /**
   * 获取模板偏移高度
   */
  getTemplateOffsetTop() {
    return this.templateEl?.parentElement!.offsetTop || 0;
  }

  /**
   * 编辑楼层
   */
  edit(deductHeight = false, setBack = false) {
    let oldItem: any = {};
    if (this.setBackStatus) this.setBackStatus++;
    if (setBack) this.setBackStatus = 1;
    else if (this.setBackStatus > 2 || !this.setBackStatus) {
      oldItem = $floor.itemSelected.getValue();
    }
    /**
     * 判断是否是自己
     */
    const isSelf = oldItem?.key === this.key;
    /**
     * 如果是自己，则直接返回
     */
    if (isSelf || this.setBackStatus === 1) return;
    /**
     * 将自己放入全局
     */
    const selectSelf = () => {
      $floor.itemSelected.next(this);

      let offsetTop = this.getTemplateOffsetTop();

      if (deductHeight && oldItem.templateEl) {
        offsetTop -= oldItem.templateEl.clientHeight;
      }
      /**
       * 将高度放入全局
       */
      $floor.marginTopChanged.next(offsetTop);
    };

    /**
     * 调用 isChange 方法，判断上一次的楼层数据是否改变
     */
    if (oldItem?.isChange && oldItem.isChange()) {
      /**
       * 如果改变了，则弹窗提示是否放弃修改
       */
      Vue.prototype
        .$confirm("当前修改尚未保存，确定放弃修改吗？", "警告", {
          confirmButtonClass: "danger-confirm",
        })
        .then(
          () => {
            /**
             * 如果放弃修改，则将老数据还原
             */
            oldItem.onReset.next(generateKey());
            selectSelf();
          },
          () => {
            // ?
          }
        );
    } else {
      selectSelf();
    }
  }

  preview(block = this.blockList) {
    const list = block ? JSON.parse(JSON.stringify(block)) : null;
    this.onChange.next({ list });
  }

  /**
   * 更新楼层数据
   */
  update(block: FloorItemBlock[]) {
    this.blockList = block;
    this.onChange.next({ list: null });
  }

  remove() {
    $floor.itemRemoved.next(this);
  }

  toJson() {
    return {
      tpl_id: this.tpl_id,
      blockList: this.blockList,
    };
  }

  destroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

/**
 * 楼层单项代理
 */
export class FloorItemProxy {
  onSave = new Subject<any>();
  onReset = new Subject<any>();
  onRemove = new Subject<any>();
  originalJson = "";
  goodsType = ""; // 商品类型 PACK 礼包商品
  onResetCallBack!: () => void;

  constructor(item: FloorItem) {
    item.onReset = this.onReset;

    this.onSave.subscribe((config) => {
      const hasError = this.hasError!();
      const $message = Vue.prototype.$message;

      if (hasError) {
        if (config?.silence) return;
        $message.error("保存失败，请检查是否存在未填数据！");
      } else {
        item.update(this.toJson!());
        this.originalJson = JSON.stringify(this.toJson!());
        if (config?.silence) return;
        this.parse!(item);
        $message.success("模块数据已保存");
      }
    });

    this.onReset.subscribe(() => {
      this.parse!(item);
      item.preview(null as any);
      if (this.onResetCallBack) this.onResetCallBack();
    });

    this.onRemove.subscribe(() => {
      item.remove();
    });

    this.parse!(item);
    this.originalJson = JSON.stringify(this.toJson!());
  }

  saveSilence() {
    this.onSave.next({
      key: generateKey(),
      silence: true,
    });
  }

  isChange() {
    const newJson = JSON.stringify(this.toJson!());
    const oldJson = this.toOldJson?.() ? JSON.stringify(this.toOldJson!()) : "";
    return this.toOldJson?.()
      ? newJson !== oldJson
      : newJson !== this.originalJson;
  }

  hasError?(): boolean;

  parse?(item: FloorItem);

  toJson?(): any[];
  toOldJson?(): any[];
}

/**
 * 楼层实例
 */
export class Floor {
  id = 0;
  key = generateKey();
  shopId = 0;
  name = "";
  url = "";
  data: FloorItem[] = [];
  clientType!: ClientType;
  pageType!: PageType;
  floor_background = "#FFFFFF";
  floor_child_background = "#FFFFFF";
  floorSwitch = new Subject<any>();
  elevatorSelectFloor = new Subject<any>();

  private originalData: any;
  private subscriptions: Subscription[] = [];

  get title() {
    switch (this.pageType) {
      case "INDEX":
        return "楼层";
      case "CHILD_INDEX":
        return "子楼层";
      case "SUBJECT_INDEX":
        return "商品专题";
    }
  }

  static createSubjectMetadata() {
    return [
      {
        tpl_id: 7,
        blockList: [],
      },
      {
        tpl_id: 6,
        blockList: [],
      },
    ] as any;
  }

  constructor(data: any, muted = false, page_type: PageType = "INDEX") {
    this.reset(data, muted, page_type);
  }

  reset(data = this.originalData, muted = false, page_type = this.pageType) {
    this.destroy();
    this.originalData = data;
    this.id = data.page_id || data.navigation_id;
    // this.shopId = data.shop_id || getApp().$store.getters.shopInfo.shop_id;
    this.name = data.page_name || data.navigation_name;
    this.clientType = data.client_type;
    this.pageType = page_type;
    this.url = data.url;
    this.floor_background = data.floor_background;
    this.floor_child_background = data.floor_child_background;

    try {
      if (page_type === "SUBJECT_INDEX") {
        if (!data.page_data) {
          this.data = Floor.createSubjectMetadata();
        } else {
          this.data = data.page_data ? JSON.parse(data.page_data) : [];
          if (!this.data[0].tpl_id) {
            this.data = Floor.createSubjectMetadata();
          }
        }
      } else {
        this.data = data.page_data ? JSON.parse(data.page_data) : [];
      }
    } catch (e) {
      if (page_type === "SUBJECT_INDEX") {
        this.data = Floor.createSubjectMetadata();
      } else {
        this.data = [];
      }
    }

    if (!this.data) this.data = [];

    this.data = this.data
      .filter((item) => item.tpl_id <= 13)
      .map((item) => new FloorItem(item))
      .filter((item) => !!item.template);

    if (!muted) {
      if (this.data[0]) {
        this.data[0].edit();
      } else {
        $floor.itemSelected.next(null!);
      }

      this.subscriptions = [
        /**
         * 监听楼层元素删除事件
         */
        $floor.itemRemoved.subscribe((item) => {
          if (!item) return;
          let index = 0;
          let isLast = false;

          /**
           * 寻找当前所在的位置，并将数据删除
           */
          this.data = this.data.filter((_item, i) => {
            const isSame = _item.key === item.key;
            if (isSame) index = i;
            return !isSame;
          });

          /**
           * 由于索引必然比新的数据大一位，所以做一个边缘检测
           */
          if (index === this.data.length) {
            index = this.data.length - 1;
            isLast = true;
          }

          if (this.data.length === 0) {
            /**
             * 如果楼层空了，则将全局的也置空
             */
            $floor.itemSelected.next(null!);
          } else {
            /**
             * 找到后调用编辑方法
             */
            this.data[index]?.edit(!isLast);
          }
        }),

        // 监听背景色状态
        $floor.setBackground.subscribe((value) => {
          if (value === "setBack_key") this.data[0].edit(false, true);
          else if (value?.type === "setBack") {
            this.floor_background = value.data;
          } else if (value?.type === "setChildBack") {
            this.floor_child_background = value.data;
          }
        }),
      ];
    }
  }

  private _publishMainFloor(floorList: any[], goodsIds: number[]) {
    let company_img =
      "https://testfcstatics.sshlqf.com/statics/attachment/normal/2020/8/19/17/24097145.png";
    floorList.forEach((item) => {
      if (item.tpl_id === 1) {
        if (
          item.blockList[0] &&
          item.blockList[0].block_value &&
          item.blockList[0].block_value.length &&
          item.blockList[0].block_value[0] &&
          item.blockList[0].block_value[0].block_value
        ) {
          company_img = item.blockList[0].block_value[0].block_value;
        }
      }
    });
    return publishFloorConfig({
      clientType: this.clientType,
      pageType: this.pageType,
      page_name: this.name,
      page_data: JSON.stringify(floorList),
      company_img: company_img,
      ids: goodsIds,
      floor_background: this.floor_background,
    });
  }

  private _publishChildFloor(floorList: any[], goodsIds: number[]) {
    return publishChildFloorConfig({
      id: this.id,
      page_data: JSON.stringify(floorList),
      ids: goodsIds,
      floor_background: this.floor_child_background,
    });
  }

  private _publishGoodsSubject(floorList: any[], goodsIds: number[]) {
    // if (!floorList[0].blockList[0] || !floorList[0].blockList[0].block_value) {
    //   return reject(new Error('请上传封面图！'));
    // }

    if (
      !floorList[1].blockList[0] ||
      floorList[1].blockList[0].block_value.length === 0
    ) {
      Vue.prototype.$message.error("请挑选至少一件商品！");
      return Promise.reject(new Error("请挑选至少一件商品！"));
    }

    return publishSGoodsSpecialSubject({
      navigation_id: this.id,
      count: floorList[1].blockList[0].block_value.length,
      page_data: JSON.stringify(floorList),
      ids: goodsIds,
    });
  }

  private _publish(floorList: any[], goodsIds: number[]) {
    switch (this.pageType) {
      case "INDEX":
        return this._publishMainFloor(floorList, goodsIds);
      case "CHILD_INDEX":
        return this._publishChildFloor(floorList, goodsIds);
      case "SUBJECT_INDEX":
        return this._publishGoodsSubject(floorList, goodsIds);
    }
  }

  /**
   * 发布楼层配置
   */
  publish(): Promise<any> {
    let floorList = this.data.map((item) => item.toJson());
    let flog = 0;
    floorList.forEach((item) => {
      if (item.tpl_id === 0) {
        flog++;
      }
      if (item.tpl_id === 5 || item.tpl_id === 6) {
        item.blockList[0].block_value = item.blockList[0].block_value.map(
          (elem) => {
            // 如果是专题 礼包跟商品混合 为了区分添加goodsType
            if (
              item.tpl_id === 6 &&
              item.blockList[0].block_type === "SUBJECT" &&
              elem.goodsType === "PACK"
            ) {
              return {
                goods_id: elem.goods_id,
                goodsType: elem.goodsType,
                mktprice: elem.mktprice,
              };
            }

            return { goods_id: elem.goods_id, mktprice: elem.mktprice };
          }
        );
      } else if (item.tpl_id === 11) {
        const data = item.blockList[0].block_value[0].content;
        if (data.substring(0, 4) === "ext_") return;
        const fromData: any = sessionStorage.getItem("shopInfo2021");
        const ext_id =
          "ext_" +
          new Date().getTime() +
          "_" +
          Math.floor(Math.random() * 1000);
        const paramsData = [
          {
            shop_id: JSON.parse(fromData).shop_id,
            page_id: this.id,
            page_type: this.pageType,
            ext_id: ext_id,
            content: item.blockList[0].block_value[0].content,
          },
        ];
        item.blockList[0].block_value[0].content = ext_id;
        saveCkeditor(paramsData);
      } else if (item.tpl_id === 9) {
        item.blockList[0]?.block_value[0]?.floorData.forEach((item1) => {
          item1.goodsList = item1.goodsList.map((item2) => {
            if (item2?.originalData.goods_id) {
              item2 = {
                originalData: {
                  goods_id: item2?.originalData.goods_id,
                  mktprice: item2?.originalData.mktprice,
                },
                id: item2.originalData.goods_id,
                goods_id: item2?.originalData.goods_id,
                mktprice: item2?.originalData.mktprice,
              };
            }
            return item2;
          });
        });
      } else {
        console.log(item.tpl_id);
        console.log(item.blockList, "blockList");
        if (item.tpl_id === 1) {
          item.blockList[0].block_value.forEach((elem) => {
            delete elem.block_opt.opt_extra_data;
          });
          // 处理轮播图热区礼包详情的数据
          item.blockList[0].block_value.forEach(({ block_opt }) => {
            if (block_opt.opt_type !== "SET_HOT") return;
            block_opt.opt_value.forEach(({ config }) => {
              if (config.type === "PACK") config.extraData = null;
            });
          });
        }
        if ([2, 3, 4, 7].includes(item.tpl_id)) {
          item.blockList.forEach((elem) => {
            delete elem.block_opt.opt_extra_data;
          });
          item.blockList.forEach(({ block_opt }) => {
            if (block_opt.opt_type !== "SET_HOT") return;
            block_opt.opt_value.forEach(({ config }) => {
              if (config.type === "PACK") config.extraData = null;
            });
          });
        }
        if (item.tpl_id === 12) {
          item.blockList[0].block_value[0].dataList.forEach((elem) => {
            delete elem.link.extraData;
          });
          console.log(item.blockList, "item");
        }
      }
    });
    if (flog > 1) {
      return new Promise<any>(() => {
        Vue.prototype.$message.error("搜索框最多只能装修 1 个");
      });
    }
    // 如果是商品专题则对商品数据进行限制
    if (sessionStorage.getItem("subjectPage") === "true") {
      let beyond = false;
      floorList.forEach((value) => {
        if (
          value.tpl_id === 6 &&
          value.blockList[0].block_value.length > 1000
        ) {
          beyond = true;
        }
      });
      if (beyond) {
        return new Promise<any>(() => {
          Vue.prototype.$message.error("专题内最多只能添加 1000 个商品");
        });
      }
    }

    return floorVersions().then((resp) => {
      sessionStorage.setItem("floorVersions-2.0", resp);
      if (resp - 0 === 0) {
        const handleData = JSON.parse(JSON.stringify(floorList));
        handleData.forEach((value, i) => {
          handleData[i] = JSON.stringify(value);
        });
        floorList.forEach((item) => {
          if (item.tpl_id === 9) {
            const num = handleData.indexOf(JSON.stringify(item));
            handleData.splice(num, 1);
          }
        });
        handleData.forEach((value, i) => {
          handleData[i] = JSON.parse(value);
        });
        floorList = handleData;
        this.floorSwitch.next(false);
      } else this.floorSwitch.next(true);
      const floorGoodsList = getAllGoods(floorList);
      let goodsIds = floorGoodsList.map((goods) => goods.goods_id);

      function parseIds(str) {
        return str
          .toString()
          .split(",")
          .filter((id) => !!id)
          .map((id) => parseInt(id));
      }

      return new Promise<any>((resolve) => {
        detectGoodsStatus(goodsIds).then((resp) => {
          const removedIds = parseIds(resp);

          if (removedIds.length > 0) {
            Vue.prototype
              .$confirm(
                "您挑选的商品中包含部分已下架的商品, 已为您自动剔除。",
                {
                  type: "warning",
                  showClose: false,
                  customClass: "goods-remove-box",
                  cancelButtonClass: "goods-remove-box-cancel",
                  closeOnPressEscape: false,
                  closeOnClickModal: false,
                }
              )
              .then(() => {
                goodsIds = goodsIds.filter((id) => !removedIds.includes(id));

                // 因为 上次调接口检测 到 点击确认之前这段时间 可能还有一些时间差
                // 所以这里再检测一次
                detectGoodsStatus(goodsIds).then((resp2) => {
                  const removedIdsAgain = parseIds(resp2);
                  goodsIds = goodsIds.filter(
                    (id) => !removedIdsAgain.includes(id)
                  );
                  floorList = removeTargetGoods(floorList, [
                    ...removedIds,
                    ...removedIdsAgain,
                  ]);

                  this._publish(floorList, goodsIds).then((resp) => {
                    this.reset(resp);
                    resolve(resp);
                  });
                });
              });
          } else {
            this._publish(floorList, goodsIds).then((resp) => {
              this.reset(resp);
              resolve(resp);
            });
          }
        });
      });
    });
  }

  destroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.data.forEach((d) => d.destroy());
  }
}

/**
 * 获取所有商品
 */
function getAllGoods(obj: any): any[] {
  if (typeof obj !== "object" && !obj) return [];
  if (obj instanceof Array) {
    return obj.reduce((idList, item) => {
      return item.block_type === "PACKLIST" /** 礼包 */ ||
        item.block_typ === "SUBJECT" /** 专题 */ ||
        item.goodsType === "PACKLIST" /** 电梯 */ // 跳过本次循环 不处理
        ? idList
        : [...idList, ...getAllGoods(item)];
    }, []);
  }

  const goodsList = Object.keys(obj).reduce((idList: any[], key) => {
    const field = obj[key];
    if (field instanceof Array) return idList.concat(...getAllGoods(field));
    return idList;
  }, []);
  if (obj.opt_type === "GOODS") goodsList.push(obj.opt_extra_data);
  if (obj.goods_id >= 0 && !obj.goodsType) goodsList.push(obj);

  return goodsList;
}

function removeTargetGoods(obj: any, targetIds: number[]) {
  if (typeof obj !== "object" || !obj) return obj;

  if (obj instanceof Array) {
    return obj.filter((item) => !!removeTargetGoods(item, targetIds));
  }

  if (targetIds.includes(obj.goods_id)) return null;
  if (obj.goods_id >= 0) return obj;

  Object.keys(obj).forEach((key) => {
    const oldValue = obj[key];
    obj[key] = removeTargetGoods(oldValue, targetIds);

    if (oldValue !== obj[key]) {
      if (obj.opt_type && obj.opt_type !== "SET_HOT") obj.opt_type = "NONE";
    }
  });

  return obj;
}

/**
 * 楼层服务
 */
export class FloorService {
  itemSelected = new BehaviorSubject(null! as FloorItem);
  itemRemoved = new BehaviorSubject(null! as FloorItem);
  setBackground = new BehaviorSubject(null! as any);
  marginTopChanged = new BehaviorSubject(0);
  scrollTo!: (y: number) => void;

  constructor() {
    getApp().$router.afterEach(() => {
      this.marginTopChanged.next(0);
    });
  }

  get(clientType: ClientType = "WAP", pageType: PageType = "INDEX") {
    return getFloorConfig({
      clientType,
      pageType,
    }).then((resp) => {
      return new Floor(resp);
    });
  }

  getChild(id: number) {
    return getChildFloorConfig(id).then((resp) => {
      sessionStorage.setItem(
        "initChildBackground",
        resp.floor_background || "#FFFFFF"
      );
      sessionStorage.setItem(
        "reeditChildBackground",
        resp.floor_background || "#FFFFFF"
      );
      return new Floor(resp, false, "CHILD_INDEX");
    });
  }

  getSubject(id: number) {
    return getGoodsSpecialSubject(id).then((resp) => {
      return new Floor(resp, false, "SUBJECT_INDEX");
    });
  }
}

export const $floor = new FloorService();
