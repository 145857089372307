import { FloorModule } from "./module.config";
import "./public.scss";

const $modules = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
  .map((type) => {
    return {
      type,
      config: require(`./${type}/$config`).default,
      template: require(`./${type}/template`).default,
      editor: require(`./${type}/editor`).default,
    };
  })
  .filter((m) => {
    return !m.config.isHidden;
  })
  .sort((m1, m2) => {
    return m1.config.order - m2.config.order;
  });

export class FloorModulesIndex {
  modules: FloorModule[] = $modules;

  getModule(type: number) {
    return this.modules.find((m) => m.type === type);
  }
}

export const $floorModules = new FloorModulesIndex();
